import { message, notification } from 'antd';
import type React from 'react';
import { useCallback, useContext, useMemo } from 'react';

import NotificationContext from '@/context/NotificationContext';

interface NotificationProviderProps {
  children: React.ReactNode;
}

const NotificationProvider: React.FunctionComponent<
  NotificationProviderProps
> = ({ children }) => {
  const showNotification = useCallback(
    (type: 'success' | 'error', title: string, messageText: string) => {
      message[type](title, 3);
      notification[type]({
        description: messageText,
        duration: 3,
        message: title,
        placement: 'bottomLeft',
      });
    },
    [],
  );

  const contextValue = useMemo(
    () => ({ showNotification }),
    [showNotification],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};

export default NotificationProvider;
