'use client';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { signIn, signOut } from '@aws-amplify/auth';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, ConfigProvider, Divider, Input } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { Controller, type Resolver, useForm } from 'react-hook-form';

import { theme } from '@/common/theme';
import companyApiClient from '@/lib/api/company/apiClient';
import { useNotification } from '@/providers/NotificationProvider';
import { LoginSchema } from '@/schema/auth/loginSchema';

type LoginType = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const router = useRouter();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginType>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver(LoginSchema) as Resolver<LoginType>,
  });

  const handleLogin = async (data: LoginType) => {
    setIsLoading(true);
    try {
      await signOut();
    } catch (e) {
      console.error('Sign out error:', e);
    }

    try {
      const { nextStep } = await signIn({
        password: data.password,
        username: data.email,
      });

      // 仮パスワードでログインした場合
      if (
        nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        router.push('/auth/newpassword');
        return;
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('メールまたはパスワードが正しくありません');
      showNotification('error', 'エラー', 'ログインに失敗しました');
      setIsLoading(false);
      return;
    }

    try {
      // 運送会社または利用運送の場合
      const company = await companyApiClient.getCompany();
      if (company.type === 'CARRIER' || company.type === 'BROKER') {
        setErrorMessage(`${company.name}様はLIFTI carriersをご利用下さい`);
        showNotification('error', 'エラー', 'ログインに失敗しました');
        return;
      }

      // リダイレクト
      router.push('/');
      showNotification('success', '成功', 'ログインしました');
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('ログインに失敗しました');
      showNotification('error', 'エラー', 'ログインに失敗しました');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <title>
        アカウントにログイン - LIFTI partners（リフティ パートナー）
      </title>
      <ConfigProvider theme={theme}>
        <div className='flex min-h-screen'>
          <div className='relative hidden w-0 flex-1 lg:block'>
            <Image
              className='absolute inset-0 size-full object-cover'
              src='/images/login-img.jpeg'
              alt=''
              width={1920}
              height={1080}
              quality={85}
            />
          </div>

          <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
            <div className='mx-auto w-full max-w-sm lg:w-96'>
              <div>
                <Image
                  width={180}
                  height={90}
                  className='h-12 w-auto'
                  src='/images/logo/partners-logo.svg'
                  alt='logo'
                />
                <h2 className='mt-6 text-3xl font-bold tracking-tight text-gray-900'>
                  アカウントにログイン
                </h2>
              </div>
              <div className='mt-8'>
                {errorMessage && (
                  <Alert type='error' message={errorMessage} banner />
                )}
                <div className='mt-6'>
                  <form
                    onSubmit={handleSubmit(handleLogin)}
                    className='space-y-6'
                  >
                    <div>
                      <label
                        htmlFor='email'
                        className='block text-sm font-medium leading-6 text-gray-900'
                      >
                        メールアドレス
                      </label>
                      <div className='relative mt-2'>
                        <Controller
                          name='email'
                          control={control}
                          render={({ field }) => (
                            <Input
                              type='email'
                              id='email'
                              {...field}
                              size='large'
                              status={errors.email && 'error'}
                            />
                          )}
                        />
                        {errors.email?.message && (
                          <div className='pointer-events-none absolute inset-y-0 bottom-7 right-0 flex items-center pr-3'>
                            <ExclamationCircleIcon
                              className='size-5 text-red-500'
                              aria-hidden='true'
                            />
                          </div>
                        )}
                        <p
                          className='mt-2 text-sm text-red-600'
                          id='company-error'
                        >
                          {errors.email?.message}
                        </p>
                      </div>
                    </div>

                    <div className='space-y-1'>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='password'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          パスワード
                        </label>
                        <div className='text-sm'>
                          <Link
                            href='/auth/forgotpassword'
                            className='font-medium text-accent no-underline hover:text-accent'
                          >
                            パスワードを忘れた場合
                          </Link>
                        </div>
                      </div>
                      <div className='mt-2'>
                        <div className='relative'>
                          <Controller
                            name='password'
                            control={control}
                            render={({ field }) => (
                              <Input.Password
                                size='large'
                                {...field}
                                iconRender={visible =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                                status={errors.password && 'error'}
                              />
                            )}
                          />
                          <p
                            className='mt-2 text-sm text-red-600'
                            id='company-error'
                          >
                            {errors.password?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Divider />
                      <Button
                        htmlType='submit'
                        type='primary'
                        className='w-full'
                        size='large'
                        loading={isLoading}
                      >
                        ログイン
                      </Button>
                    </div>
                    <div className='flex flex-col'>
                      <p className='mb-0 flex justify-center text-sm'>
                        アカウントをお持ちでない方は{' '}
                        <Link
                          href={
                            process.env.NEXT_PUBLIC_FORM_BRIDGE_REGISTER_URL ??
                            ''
                          }
                          className='text-accent'
                        >
                          登録する
                        </Link>
                      </p>
                      <p className='mt-4 flex justify-center text-sm'>
                        利用運送・運送会社様は{' '}
                        <Link
                          href='https://app.lifti.jp/auth/login'
                          className='text-accent'
                        >
                          こちらへ
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </>
  );
};

export default LoginPage;
