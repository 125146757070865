import { getAccessToken } from '@/utils/amplify/getAccessToken';

const customFetch: (
  input: RequestInfo,
  init?: RequestInit,
  token?: string,
) => Promise<Response> = async (input, init) => {
  try {
    const accessToken = await getAccessToken();

    const requestInit: RequestInit = {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return fetch(input, requestInit);
  } catch (error) {
    console.error('Error getting session', error);
    return fetch(input, init);
  }
};

export default customFetch;
